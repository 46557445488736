/* eslint-disable no-console */
function Logger() {}

Logger.getLogger = () => {
    const consoleShim = {
        log: (message, ...rest) => {
            console.log(`${window.logPrefix()}${message}`);
            if (rest.length > 0) {
                console.log(...rest);
            }
        },
        debug: (message, ...rest) => {
            console.log(`${window.logPrefix()}${message}`);
            if (rest.length > 0) {
                console.log(...rest);
            }
        },
        info: (message, ...rest) => {
            console.log(`${window.logPrefix()}${message}`);
            if (rest.length > 0) {
                console.log(...rest);
            }
        },
        warn: (message, ...rest) => {
            console.log(`${window.logPrefix()}${message}`);
            if (rest.length > 0) {
                console.log(...rest);
            }
        },
        error: (message, ...rest) => {
            console.log(`${window.logPrefix()}${message}`);
            if (rest.length > 0) {
                console.log(...rest);
            }
        },
    };
    return consoleShim;
};
module.exports = {
    Logger,
};
