/* eslint-disable no-console */
require('navigator.locks');
const isEmail = require('validator/lib/isEmail');

// Since web3 is stable, we include using <script> to keep this class smaller
// const Web3 = require('web3');
// for FE, done in app.js
// global.web3 = new Web3();

const luxon = require('luxon');

const debugLoader = false;
let indexR = 0;
if (debugLoader) { console.log(`indexR [${indexR++}]`); }
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

if (debugLoader) { console.log(`indexR [${indexR++}]`); }
const MetaMaskOnboarding = require('@metamask/onboarding');

if (debugLoader) { console.log(`indexR [${indexR++}]`); }
const ConstantsCommon = require('../ConstantsCommon');

if (debugLoader) { console.log(`indexR [${indexR++}]`); }
const { Logger } = require('../logger/Logger');

// const { checkRefreshGetAccessTokenAsync } = require('../misc/api');
if (debugLoader) { console.log(`indexR [${indexR++}]`); }
const { Web3Helper } = require('../misc/Web3Helper');

if (debugLoader) { console.log(`indexR [${indexR++}]`); }
const sigUtil = require('../../node_modules/@metamask/eth-sig-util/dist/sign-typed-data');

if (debugLoader) { console.log(`indexR [${indexR++}]`); }
const AuthManager = require('./AuthManager');

if (debugLoader) { console.log(`indexR [${indexR++}]`); }
const BrowserAuthRenderer = require('../misc/BrowserAuthRenderer');

if (debugLoader) { console.log(`indexR [${indexR++}]`); }
const GlobalData = require('../dao/GlobalData');

if (debugLoader) { console.log(`indexR [${indexR++}]`); }
const UtilsCommonMin = require('../misc/UtilsCommonMin');

if (debugLoader) { console.log(`indexR [${indexR++}]`); }
const preAppModals = require('../modals/pre-app');

const sleep = async (ms) => { return new Promise((resolve) => { setTimeout(resolve, ms); }); };

const switchNetwork = async (chainIdIn) => {
    $('#wallet-chain-id-switch').attr('disabled', true);
    const errMsg = 'Failed to switch network';
    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex(chainIdIn) }],
        });
    } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
            // await window.ethereum.request({
            //     method: 'wallet_addEthereumChain',
            //     params: [
            //     {
            //         chainName,
            //         chainId: web3.utils.toHex(chainIdIn),
            //         nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
            //         rpcUrls: ['https://polygon-rpc.com/']
            //     }
            //     ]
            // });
        }
        showToast(errMsg);
    } finally {
        $('#wallet-chain-id-switch').attr('disabled', false);
    }
};

const updateTopBannerOnChainChange = (chainId) => {
    const chainIdHex = web3.utils.toHex(ConstantsCommon.chainId);
    if (chainId !== chainIdHex) {
        const chainIdInt = Number.parseInt(web3.utils.toBN(`${chainId}`).toString(10), 10);
        const chainIdStr = ConstantsCommon.ChainStr(chainIdInt);
        const msg = `<div>App is on ${ConstantsCommon.chainName}, `
        + `but your wallet is on ${chainIdStr !== chainId ? `${chainIdStr}` : `chain ${chainId}`}</div>
        <div>Tap <a href="#" onclick="bobbobAuth.switchNetwork(${ConstantsCommon.chainId});">here to swith</a></div>`;
        $('#top-banner-restart-button').hide();
        $('#top-banner-dismiss-button').show();
        $('#top-banner').show();
        $('#top-banner-msg').html(msg);
        $('#top-banner-login').show();
        $('#top-banner-msg-login').html(msg);
    } else {
        $('#top-banner').hide();
        $('#top-banner-login').hide();
    }
};

const updateWalletToolOnChainChange = (chainId) => {
    const chainIdHex = web3.utils.toHex(ConstantsCommon.chainId);
    if (chainId !== chainIdHex) {
        $('#wallet-chain-id-switch').show();
    } else {
        $('#wallet-chain-id-switch').hide();
    }
    const chainIdInt = Number.parseInt(web3.utils.toBN(`${chainId}`).toString(10), 10);
    $('#wallet-chain-id').html(ConstantsCommon.ChainStr(chainIdInt));
};

const updateTopBannerOnDisconnected = (isConnected) => {
    let msg;
    if (!isConnected) {
        msg = '<div>Your wallet has been disconnected, please restart BOBBOB to reestablish the wallet connection.</div>';
        $('#top-banner-restart-button').show();
        $('#top-banner-dismiss-button').hide();
        $('#top-banner').show();
        $('#top-banner-msg').html(msg);
        $('#top-banner-login').show();
        $('#top-banner-msg-login').html(msg);
    } else {
        $('#top-banner-msg').html(msg);
        $('#top-banner').hide();
        $('#top-banner-login').hide();
    }
};

const handleDisconnected = (error) => {
    console.log(`${window.logPrefix()}handleDisconnected error [${JSON.stringify(error)}]`);
    // updateTopBannerOnDisconnected(false);
};
const handleConnected = (connectInfo) => {
    console.log(`${window.logPrefix()}handleConnected connectInfo [${JSON.stringify(connectInfo)}]`);
    updateTopBannerOnDisconnected(true);
};
const handleChainChanged = (_chainId) => {
    // We recommend reloading the page, unless you must do otherwise
    // window.location.reload();
    updateTopBannerOnChainChange(_chainId);
    updateWalletToolOnChainChange(_chainId);
};

const handleAccountsChanged = (accountsChanged) => {
    console.log(`${window.logPrefix()}handleAccountsChanged -- IN`);
    if (accountsChanged.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
        console.log('Please connect to MetaMask.');
        $('.modal').modal('hide');
        $('#modal-logged-out').modal('show');
        // window.location.reload();
    } else {
        // balanceManager may not be initialised yet, ie account changed in login page
        // if (typeof(balanceManager) !== 'undefined' && accountsChanged[0] !== balanceManager.userAddressEthMm) {
        //     await authManager.linkMetaMask(accountsChanged[0], false);
        //     // window.location.reload();
        // }
        const { registeredAddress, registeredAddressSource } = authManager.getRegisteredAddressSource();
        // let registeredAddress;
        // let registeredAddressSource;
        // if (authManager.authInfo) {
        //     registeredAddress = authManager.authInfo.address;
        //     registeredAddressSource = authManager.authInfo.addressSource;
        //     console.log(`${window.logPrefix()}handleAccountsChanged authManager.authInfo.addressSource [${authManager.authInfo.addressSource}]`);
        //     // window.balanceManager can be undefined in login page
        // } else if (window.balanceManager && window.balanceManager.userAddressEthMm) {
        //     registeredAddress = window.balanceManager.userAddressEthMm;
        //     registeredAddressSource = window.balanceManager.userAddressEthMmSource;
        //     console.log(`${window.logPrefix()}handleAccountsChanged window.balanceManager.userAddressEthMmSource [${window.balanceManager.userAddressEthMmSource}]`);
        // }
        if (registeredAddress && registeredAddressSource === 'mm') {
            let foundAccount = false;
            // user has logged in previosuly, jwt is still valid
            for (let i = 0; i < accountsChanged.length; i++) {
                const accountTmp = accountsChanged[i];
                console.log(`i [${i}] accountTmp [${accountTmp}]`);
                if (registeredAddress === accountTmp) {
                    foundAccount = true;
                    break;
                }
            }
            // account no longer exists
            if (!foundAccount) {
                $('.modal').modal('hide');
                $('#modal-logged-out').modal('show');
                // await authManager.linkMetaMask(accountsChanged[0], false);
            }
        }
    }
    console.log(`${window.logPrefix()}handleAccountsChanged -- OUT`);
};

const checkMetamaskOnboarding = (skipOnboarding) => {
    if (typeof window.ethereum === 'undefined' || !window.ethereum.isMetaMask) {
        console.log(`${window.logPrefix()}MetaMask is not installed`);
        if (!skipOnboarding && !MetaMaskOnboarding.isMetaMaskInstalled()) {
            const onboarding = new MetaMaskOnboarding();
            onboarding.startOnboarding();
        }
        return false;
    }
    return true;
};

const initialiseMetamask = () => {
    if (!checkMetamaskOnboarding(true)) {
        return false;
    }
    // if (!window.ethereum.isConnected()) {
    //     return false;
    // }
    window.ethereum.on('connect', handleConnected);
    window.ethereum.on('disconnect', handleDisconnected);
    window.ethereum.on('chainChanged', handleChainChanged);
    window.ethereum.on('accountsChanged', handleAccountsChanged);
    return true;
};

const linkMetaMask = async () => {
    const addressEthMmSource = 'mm';
    const errMsg = 'Failed to link MetaMask address';
    const successMsg = 'MetaMask address linked successfully';
    const elem = '.button-metamask-connect, .button-ledger-connect, .button-bbwallet-connect, .button-bblwallet-connect';
    try {
        window.hideToast();
        $(elem).attr('disabled', true);
        if (!checkMetamaskOnboarding(false)) {
            $(elem).attr('disabled', false);
            return;
        }
        const isConnected = window.ethereum.isConnected();
        // if (!isConnected) {
        //     const msg = '<div>Your wallet is not connected</div>';
        //     $('#button-proceed-anyway').hide();
        //     $('#notice-msg').html(msg);
        //     $('#button-notice').attr('onclick', `$('#modal-notice').modal('hide');`);
        //     $('#modal-notice').modal('show');
        //     $(elem).attr('disabled', false);
        //     return;
        // }
        console.log(`${window.logPrefix()}isConnected [${isConnected}]`);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        console.log(`${window.logPrefix()}chainId [${chainId}] [${typeof chainId}]`);

        const addressEthMm = accounts[0];
        if (addressEthMm === balanceManager.userAddressEthMm && balanceManager.userAddressEthMmSource === 'mm') {
            const msg = '<div>The wallet address is already linked, please select another address in your wallet and tap Link Metamask Wallet again.</div>';
            $('#button-proceed-anyway').hide();
            $('#notice-msg').html(msg);
            $('#button-notice').attr('onclick', '$(\'#modal-notice\').modal(\'hide\');');
            $('#modal-notice').modal('show');
            $(elem).attr('disabled', false);
            return;
        }
        // newer MM versions hides MM, so UI is stuck. Check for chain diff here and show error
        const chainIdHex = web3.utils.toHex(ConstantsCommon.chainId);
        if (chainId !== chainIdHex) {
            const chainIdInt = Number.parseInt(web3.utils.toBN(`${chainId}`).toString(10), 10);
            const chainIdStr = ConstantsCommon.ChainStr(chainIdInt);
            const msg = `<div>App is on ${ConstantsCommon.chainName}, `
            + `but your wallet is on ${chainIdStr !== chainId ? `${chainIdStr}` : `chain ${chainId}`}</div>
            <div>Tap <a href="#" onclick="$('#modal-notice').modal('hide');bobbobAuth.switchNetwork(${ConstantsCommon.chainId});">here to swith</a> before adding a
            wallet address to the app.</div>`;
            $('#button-proceed-anyway').hide();
            $('#notice-msg').html(msg);
            $('#button-notice').attr('onclick', '$(\'#modal-notice\').modal(\'hide\');');
            $('#modal-notice').modal('show');
            $('#wallet-chain-id-switch').show();
            $(elem).attr('disabled', false);
            return;
        }
        const {
            data: dataAuth,
            domainHash: _domainHash,
            messageHash: _messageHash,
        } = await Web3Helper.getEip712AuthData(addressEthMm, 'add-wallet');
        const hash = dataAuth.message.challenge;
        const deadline = dataAuth.message.deadline;
        const fromAddressEth = addressEthMm;
        const web3Metamask = new window.Web3(window.ethereum);
        const sig = await Web3Helper.metaMaskSignTypedDataV3Async(fromAddressEth, dataAuth, web3Metamask);

        // $('.button-metamask-connect').attr('disabled', true);
        const accessTokenToUse = await authManager.checkRefreshGetAccessTokenAsync();
        const payload = {
            addressEthMm,
            addressEthMmSource,
            hash,
            deadline,
            sig,
        };
        window.onTx();
        const response = await fetch(
            `${ConstantsCommon.API_URL}/wallet/metamask`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessTokenToUse}`,
                },
                body: JSON.stringify(payload),
            },
        );
        window.onRx();
        const statusCode = response.status;
        console.log(`${window.logPrefix()}linkMetaMask statusCode [${statusCode}]`);
        if (statusCode === 200) {
            const {
                accessTokenInfoRefreshToken,
                avatarUpdateInfo,
                userBalanceData,
            } = await response.json();
            const authInfo = {
                address: addressEthMm,
                addressSource: addressEthMmSource,
                hash,
                deadline,
                sig,
                // hasWeb2: accessTokenInfoRefreshToken.idTokenInfo.hasWeb2,
            };
            authManager.processAccessTokenInfoRefreshToken(authInfo, accessTokenInfoRefreshToken);
            getAddressSourceFriendlyName(addressEthMmSource, true);

            window.ledgerAppEth = undefined;
            window.web3Metamask = web3Metamask;
            // $('#address-eth-mm-source').html('Metamask');
            // $('#address-eth-mm').html(getAddressTemplate(addressEthMm, 'address-eth-mm'));
            balanceManager.userAddressEthMm = addressEthMm;
            balanceManager.userAddressEthMmSource = addressEthMmSource;
            $('#top-button-metamask-connect').hide();
            $('#top-button-metamask-link').hide();
            $('#top-button-ledger-connect').hide();
            $('#top-button-ledger-link').hide();
            $('#top-button-bbwallet-connect').hide();
            $('#top-button-bbwallet-link').hide();
            $('#top-button-bblwallet-connect').hide();
            $('#top-button-bblwallet-link').hide();
            // below will get balance
            // but await will cause balance to unavailable and userAddressEthMm is already set above
            // this means there is a window where activeWallet is set, but there is no balance available
            // which can use undefined exception when getting activeWallet's balance (eg checking if can
            // buy an item). Change BE to return balance when linking wallet, which means setting userAddressEthMm
            // above is not needed.
            // $('#default-to-custodial').prop('checked', false).trigger('change');
            $('#default-to-custodial').prop('checked', false);
            balanceManager.processBalanceData(userBalanceData);

            processProfileAvatar(avatarUpdateInfo);
            window.showToast(successMsg, true);
            $(elem).attr('disabled', false);
        } else if (statusCode === 401) {
            $(elem).attr('disabled', false);
            authManager.handle401Redirect();
        } else {
            $(elem).attr('disabled', false);
            await authManager.showDataErrorToast(response, errMsg);
        }
    } catch (error) {
        $(elem).attr('disabled', false);
        console.log(`${window.logPrefix()}${errMsg}: ${error.stack || error.message}`);
        window.showToast(`${errMsg}: ${error.message}`);
    }
};

// const connectLedger = async (event, elem, signIn, hideSuccess) => {
//     console.log('creating transport');
//     try {
//         const transport = await Transport.create();
//         console.log('transport created');
//         listen(({
//             id, date, type, message, ...rest
//         }) => {
//             console.log('debug', {
//                 message: type + (message ? `: ${message}` : ''),
//                 // $FlowFixMe
//                 ...rest,
//             });
//         });
//         const eth = new AppEth(transport);
//         const result = await eth.getAddress('44\'/60\'/0\'/0/0');
//         console.log(`result [${JSON.stringify(result)}]`);
//         setHtmlShowHide('#login-msg', result.address);
//     } catch (error) {
//         console.log(`Failed to connect to Ledger: [${error.stack || error.message}]`);
//         // Unable to claim interface
//         if (error.name === 'TransportInterfaceNotAvailable') {
//             setHtmlShowHide('#login-msg', 'Ledger device is being used by another application');
//         } else if (error.name === 'LockedDeviceError') {
//             setHtmlShowHide('#login-msg', 'Ledger device is locked');
//         } else {
//             setHtmlShowHide('#login-msg', 'Failed to connect to Ledger device');
//         }
//     }
// };

const connectMetamask = async (event, elem, signIn, hideSuccess, skipOnboarding) => {
    $(elem).attr('disabled', true);
    if (!checkMetamaskOnboarding(skipOnboarding)) {
        $(elem).attr('disabled', false);
        return null;
    }
    const isConnected = window.ethereum.isConnected();
    // if (!isConnected) {
    //     $(elem).attr('disabled', false);
    //     return null;
    // }
    // window.ethereum.on('connect', handleConnected);
    // window.ethereum.on('disconnect', handleDisconnected);
    // window.ethereum.on('chainChanged', handleChainChanged);
    console.log(`${window.logPrefix()}calling eth_requestAccounts isConnected [${isConnected}]`);
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        console.log(`${window.logPrefix()}chainId [${chainId}] [${typeof chainId}]`);
        // updateTopBannerOnChainChange(chainId);

        window.web3Metamask = new Web3(window.ethereum);
        let account;
        let success;
        if (signIn) {
            const simError = false;
            if (simError) {
                throw new Error('SIM ERROR');
            }
            // Have encountered account is undefined, when wallet is not initialised properly?
            // signinUsingMetamask() will handle this case
            account = accounts[0];
            // newer MM versions hides MM, so UI is stuck. Check for chain diff here and show error
            const chainIdHex = web3.utils.toHex(ConstantsCommon.chainId);
            if (chainId !== chainIdHex) {
                updateTopBannerOnChainChange(chainId);
                updateWalletToolOnChainChange(chainId);
                $(elem).attr('disabled', false);
                // authManager.renderer.displayLoginMsg('Switch network');
                return null;
            }

            const accessTokenInfoRefreshToken = await authManager.signinUsingMetamask(
                account,
                false,
                false,
                async (from, data, _domainHash, _messageHash) => {
                    // console.log(`${window.logPrefix()}data [${JSON.stringify(data, null, 4)}]`);
                    const sig = await Web3Helper.metaMaskSignTypedDataV3Async(from, data);
                    return sig;
                },
                'mm',
            );
            success = !!accessTokenInfoRefreshToken;
            // don't need to enable elem if successful, since we will be redirecting to homepage
            if (!success) {
                $(elem).attr('disabled', false);
            }
        } else {
            // This flow is slightly different from ledger and bbwallet
            const { registeredAddress, registeredAddressSource } = authManager.getRegisteredAddressSource();
            // let registeredAddress;
            // let registeredAddressSource;
            // if (authManager.authInfo) {
            //     registeredAddress = authManager.authInfo.address;
            //     registeredAddressSource = authManager.authInfo.addressSource;
            //     console.log(`${window.logPrefix()}connectMetamask authManager.authInfo.addressSource [${authManager.authInfo.addressSource}]`);
            // } else if (window.balanceManager.userAddressEthMm) {
            //     registeredAddress = window.balanceManager.userAddressEthMm;
            //     registeredAddressSource = window.balanceManager.userAddressEthMmSource;
            //     console.log(`${window.logPrefix()}connectMetamask window.balanceManager.userAddressEthMmSource [${window.balanceManager.userAddressEthMmSource}]`);
            // }
            // if BE has been unlinked by another app instance, we check it here
            if (registeredAddress && registeredAddressSource === 'mm' && !!window.balanceManager.userAddressEthMm) {
                // user has logged in previosuly, jwt is still valid
                for (let i = 0; i < accounts.length; i++) {
                    const accountTmp = accounts[i];
                    console.log(`i [${i}] accountTmp [${accountTmp}] registeredAddress [${registeredAddress}]`);
                    // if (window.balanceManager.userAddressEthMm !== undefined && window.balanceManager.userAddressEthMm === accountTmp) {
                    if (registeredAddress === accountTmp) {
                        account = accountTmp;
                        break;
                    }
                }
                if (!account) {
                    $('.modal').modal('hide');
                    $('#modal-logged-out-msg').html('The wallet address does not match linked wallet, please sign-in again.');
                    $('#modal-logged-out').modal('show');
                    success = false;
                } else {
                    // success = await authManager.reconnectMetaMaskAddress(account, hideSuccess);
                    if (!hideSuccess) {
                        const successMsg = 'MetaMask connected successfully';
                        showToast(successMsg, true);
                    }
                    success = true;
                }
            } else {
                // logged in via social media, need user to sign in via wallet
                // TODO: need time to think of this flow, for now, we disallow this code flow, it hide connect button
                // if logged in via social
                // keep this flow only for logging in,
                // for adding/changing walllet, do it via profile
                // account = accounts[0];
                // const accessTokenInfoRefreshToken = await authManager.signinUsingMetamask(account, false, false, true, async (from, data) => {
                //     const sig = await Web3Helper.metaMaskSignTypedDataV3Async(from, data);
                //     return sig;
                // });
                // success = !!accessTokenInfoRefreshToken;
                // throw new Error('Unsupported code flow');
                const doAddAddress = true;
                if (!doAddAddress) {
                    const msg = /* html */`
                        <div>There is no registered wallet address to connect to Metamask</div>
                        <div>Tap <a href="#" onclick="
                                                    $('#modal-notice').modal('hide');
                                                    $('#tab_mya').tab('show');
                                                    $('#tab_mya-balance').tab('show');
                                                    $('#button-link-metamask')[0].scrollIntoView({ behavior: 'instant' });
                                                    bobbob.HtmlHelper.pulseElement('#button-link-metamask', '#button-link-metamask');
                                                    ">here to link your wallet address</a>
                        </div>
                    `;
                    $('#button-proceed-anyway').hide();
                    $('#notice-msg').html(msg);
                    $('#button-notice').attr('onclick', '$(\'#modal-notice\').modal(\'hide\');');
                    $('#modal-notice').modal('show');
                    success = false;
                } else {
                    const refreshApp = true;
                    if (refreshApp) {
                        $('.modal').modal('hide');
                        $('#modal-different-wallet-sign-in').modal('show');
                        success = true;
                    } else {
                        // There is a problem with this flow, it does not check linkMetaMask() is successful
                        // and hides the connect button.
                        await linkMetaMask();
                        success = true;
                    }
                }
            }
            $(elem).attr('disabled', false);
        }
        if (!success) {
            window.web3Metamask = null;
            // window.ethereum.removeListener('connect', handleConnected);
            // window.ethereum.removeListener('disconnect', handleDisconnected);
            // window.ethereum.removeListener('chainChanged', handleChainChanged);
            // window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
            $('#top-button-metamask-connect').show();
        } else {
            $('#top-button-metamask-connect').hide();
            $('#top-button-metamask-link').hide();
            $('#top-button-ledger-connect').hide();
            $('#top-button-ledger-link').hide();
            $('#top-button-bbwallet-connect').hide();
            $('#top-button-bbwallet-link').hide();
            $('#top-button-bblwallet-connect').hide();
            $('#top-button-bblwallet-link').hide();
            // updateTopBannerOnChainChange(chainId);
            updateWalletToolOnChainChange(chainId);
            // window.ethereum.on('accountsChanged', handleAccountsChanged);
        }
        return account;
    } catch (error) {
        console.log(`${window.logPrefix()}Failed to connect to metamask: [${error.stack || error.message}]`);
        showToast(error.message);
        $('#top-button-metamask-connect').show();
    }
    $(elem).attr('disabled', false);
    return null;
};

const abortAuthFlowCalls = () => {
    // can cancell any existing remote calls
    authManager.authFlowRid += 1;
    authManager.authFlowController.abort();
};

const ridProviderFn = () => {
    return authManager.authFlowRid;
};

const getRidController = () => {
    authManager.authFlowRid += 1;
    authManager.authFlowController.abort();
    authManager.authFlowController = new AbortController();
    const rid = authManager.authFlowRid;
    const controller = authManager.authFlowController;
    return {
        rid,
        controller,
    };
};

const hostedLogin = () => {
    deleteCognitoCookie();
    oauth.fetchAuthorizationCode();
};

// const getPasswordFieldHtml = (autocomplete = 'off') => {
//     return /* html */`
//         <input
//             id="password"
//             type="password"
//             name="password"
//             class="form-control"
//             required
//             autocomplete="off"
//             onkeypress="onEnterKeyPressPassword(event, this);"
//         ></input>
//         <label for="password">Password</label>
//         <div
//             style="position: absolute;top: calc(50% - 12px);right: 8%;cursor: pointer;" onclick="bobbobAuth.toggleShowHidePassword('#icon-show-password', '#password');">
//             <i id="icon-show-password" style="height:24px" class="far fa-eye-slash"></i>
//         </div>
//     `;
// };

// const getPasswordCheckerHtml = () => {
//     return /* html */`
//         <div class="div-row-v-md">
//             <div class="progress progress-normal">
//                 <div id="password-checker-progress" class="progress-bar bg-init" role="progressbar" style="width: 0%;transition:none !important;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
//             </div>
//             <div style="font-size: small;" class="div-row-v-md">
//                 <div style="display:flex; align-items: center;" class="button-row">
//                     <i id="icon-low-case" class="fas fa-circle icon-small" aria-hidden="true"></i>
//                     <div>Lowercase</div>
//                 </div>
//                 <div style="display:flex; align-items: center;" class="button-row">
//                     <i id="icon-upper-case" class="fas fa-circle icon-small" aria-hidden="true"></i>
//                     <div>Uppercase</div>
//                 </div>
//                 <div style="display:flex; align-items: center;" class="button-row">
//                     <i id="icon-one-number" class="fas fa-circle icon-small" aria-hidden="true"></i>
//                     <div>Number (0-9)</div>
//                 </div>
//                 <div style="display: none">
//                     <div style="display:flex; align-items: center;" class="button-row">
//                         <i id="icon-one-special-char" class="fas fa-circle icon-small" aria-hidden="true"></i>
//                         <div>Special character (!@#$%^&amp;*)</div>
//                     </div>
//                 </div>
//                 <div style="display:flex; align-items: center;" class="button-row">
//                     <i id="icon-eight-character" class="fas fa-circle icon-small" aria-hidden="true"></i>
//                     <div>At least 8 characters</div>
//                 </div>
//                 <div style="display:flex; align-items: center;" class="button-row">
//                     <i id="icon-no-spaces" class="fas fa-circle icon-small" aria-hidden="true"></i>
//                     <div>No spaces</div>
//                 </div>
//             </div>
//         </div>
//     `;
// };

const checkPasswordStrength = (password, enableSpecialChar = false) => {
    let strength = 0;
    let strengthTotal = 0;
    // If password contains both lower and uppercase characters
    // if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
    //     strength += 1;
    //     lowUpperCase.classList.remove('fa-circle');
    //     lowUpperCase.classList.add('fa-check');
    // } else {
    //     lowUpperCase.classList.add('fa-circle');
    //     lowUpperCase.classList.remove('fa-check');
    // }
    // lowercase
    strengthTotal += 1;
    if (password.match(/[a-z].*/)) {
        strength += 1;
        $('#icon-low-case').removeClass('fa-circle');
        $('#icon-low-case').removeClass('icon-small');
        $('#icon-low-case').addClass('fa-check');
    } else {
        $('#icon-low-case').addClass('fa-circle');
        $('#icon-low-case').addClass('icon-small');
        $('#icon-low-case').removeClass('fa-check');
    }
    // uppercase
    strengthTotal += 1;
    if (password.match(/[A-Z].*/)) {
        strength += 1;
        $('#icon-upper-case').removeClass('fa-circle');
        $('#icon-upper-case').removeClass('icon-small');
        $('#icon-upper-case').addClass('fa-check');
    } else {
        $('#icon-upper-case').addClass('fa-circle');
        $('#icon-upper-case').addClass('icon-small');
        $('#icon-upper-case').removeClass('fa-check');
    }
    // If it has numbers and characters
    strengthTotal += 1;
    if (password.match(/([0-9])/)) {
        strength += 1;
        $('#icon-one-number').removeClass('fa-circle');
        $('#icon-one-number').removeClass('icon-small');
        $('#icon-one-number').addClass('fa-check');
    } else {
        $('#icon-one-number').addClass('fa-circle');
        $('#icon-one-number').addClass('icon-small');
        $('#icon-one-number').removeClass('fa-check');
    }
    // If it has one special character
    if (enableSpecialChar) {
        strengthTotal += 1;
        if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
            strength += 1;
            $('#icon-one-special-char').removeClass('fa-circle');
            $('#icon-one-special-char').removeClass('icon-small');
            $('#icon-one-special-char').addClass('fa-check');
        } else {
            $('#icon-one-special-char').addClass('fa-circle');
            $('#icon-one-special-char').addClass('icon-small');
            $('#icon-one-special-char').removeClass('fa-check');
        }
    }
    // If password is greater than 7
    strengthTotal += 1;
    if (password.length > 7) {
        strength += 1;
        $('#icon-eight-character').removeClass('fa-circle');
        $('#icon-eight-character').removeClass('icon-small');
        $('#icon-eight-character').addClass('fa-check');
    } else {
        $('#icon-eight-character').addClass('fa-circle');
        $('#icon-eight-character').addClass('icon-small');
        $('#icon-eight-character').removeClass('fa-check');
    }
    strengthTotal += 1;
    if (password.length > 0 && !password.match(/[\s]/)) {
        strength += 1;
        $('#icon-no-spaces').removeClass('fa-circle');
        $('#icon-no-spaces').removeClass('icon-small');
        $('#icon-no-spaces').addClass('fa-check');
    } else {
        $('#icon-no-spaces').addClass('fa-circle');
        $('#icon-no-spaces').addClass('icon-small');
        $('#icon-no-spaces').removeClass('fa-check');
    }

    // If value is less than 2
    const eidProgress = '#password-checker-progress';
    $(`${eidProgress}`).removeClass('bg-init');
    $(`${eidProgress}`).removeClass('bg-success');
    $(`${eidProgress}`).removeClass('bg-warning');
    $(`${eidProgress}`).removeClass('bg-danger');
    const percent = (strength / strengthTotal) * 100;
    $(`${eidProgress}`).css('width', `${percent.toFixed(1)}%`);
    if (percent === 0) {
        $(`${eidProgress}`).addClass('bg-init');
    } else if (percent < 25) {
        $(`${eidProgress}`).addClass('bg-danger');
    } else if (percent < 75) {
        $(`${eidProgress}`).addClass('bg-warning');
    } else {
        $(`${eidProgress}`).addClass('bg-success');
    }
    return {
        strength,
        strengthTotal,
    };
};

const toggleShowHidePassword = (elemIcon, elemPassword, forceHide) => {
    const state = $(elemPassword).attr('type');
    if (state === 'text' || forceHide) {
        $(elemPassword).attr('type', 'password');
        $(elemIcon).addClass('fa-eye-slash');
        $(elemIcon).removeClass('fa-eye');
    } else {
        $(elemPassword).attr('type', 'text');
        $(elemIcon).removeClass('fa-eye-slash');
        $(elemIcon).addClass('fa-eye');
    }
};

const onPasswordKeyup = (e, elem) => {
    $('#password-checker-section').show();
    // $('#change-password-checker-section').show();
    const password = $(elem).val();
    checkPasswordStrength(password);
};

const setHtmlShowHide = (elem, html) => {
    $(elem).html(html);
    if (html) {
        $(elem).show();
    } else {
        $(elem).hide();
    }
};

const cognitoNavSignIn = (_e, _elem) => {
    $('#button-cognito-sign-in').attr('disabled', false);

    $('.sign-up-flow').hide();
    $('.reset-password-flow').hide();
    $('.change-password-flow').hide();
    $('.confirm-account-flow').hide();
    $('.sign-in-flow').show();
    $('#label-username').html('Username or Email');
    $('#username').removeAttr('readonly');
    $('#password-checker-section').hide();
    toggleShowHidePassword('#icon-show-password', '#password', true);

    // $('#sign-up-footer-section').hide();
    // $('#email-wrapper').hide();
    // $('#sign-in-footer-section').show();
    // $('#username').val('');
    // $('#form-cognito-sign-in').trigger('reset');
    $('#label-username').html('Username or Email');
    // $('#change-password-wrapper').html('');
    // const password = $('#password').val();
    // $('#password-wrapper').html(getPasswordFieldHtml('new-password'));
    // $('#password').val(password);
    // $('#password').val('');
    $('#password').removeAttr('onkeyup');
    // $('#password-checker-section').html('');
    // $('#password-checker-section').hide();
    setHtmlShowHide('#login-msg', '');
    const isMobile = mobileCheck();
    if (!isMobile) {
        // on mobile, this triggers the saved password popup
        // on desktop, prevents chrome from autofilling
        // No, problem is there is no <form></form> tags
        $('#username').trigger('focus');
    }
};

const cognitoNavSignUp = (_e, _elem) => {
    abortAuthFlowCalls();
    $('#button-cognito-sign-up').attr('disabled', false);

    $('.reset-password-flow').hide();
    $('.change-password-flow').hide();
    $('.confirm-account-flow').hide();
    $('.sign-in-flow').hide();
    $('.sign-up-flow').show();
    $('#label-username').html('Username');
    $('#username').removeAttr('readonly');
    $('#password-checker-section').hide();
    const password = $('#password').val();
    if (password.length > 0) {
        checkPasswordStrength(password);
        $('#password-checker-section').show();
    }
    toggleShowHidePassword('#icon-show-password', '#password', true);

    // $('#signin-web2-custom').hide();
    // $('#signup-web2-custom').show();
    // $('#sign-up-footer-section').show();
    // $('#email-wrapper').show();
    // $('#sign-in-footer-section').hide();
    // $('#username').val('');
    $('#label-username').html('Username');
    // $('#change-password-wrapper').html('');
    // const password = $('#password').val();
    // $('#password-wrapper').html(getPasswordFieldHtml('new-password'));
    // $('#password').val(password);
    // $('#password').val('');
    $('#password').attr('onkeyup', 'bobbobAuth.onPasswordKeyup(event, this);');
    // $('#change-password-checker-section').html('');
    // $('#password-checker-section').html(getPasswordCheckerHtml());
    // $('#password-checker-section').hide();
    setHtmlShowHide('#login-msg', '');
    const isMobile = mobileCheck();
    if (!isMobile) {
        // on mobile, this triggers the saved password popup
        $('#username').trigger('focus');
    }
};

const cognitoNavForgottenPassword = (_e, _elem) => {
    abortAuthFlowCalls();
    $('#button-cognito-reset-password').attr('disabled', false);
    setHtmlShowHide('#login-msg', 'Enter your Username below and we will send a message to reset your password');
    // showToast('Enter your Username below and we will send a message to reset your password', true);
    // $('#reset-username').val('');
    // $('#password-wrapper').hide();
    // $('#sign-in-footer-section').hide();
    // $('#sign-in-up-web2-custom').hide();
    // $('#forgot-password-web2-custom').show();
    // $('#cognito-reset-password-section').show();

    $('.change-password-flow').hide();
    $('.confirm-account-flow').hide();
    $('.sign-in-flow').hide();
    $('.sign-up-flow').hide();
    $('.reset-password-flow').show();
    $('#username').removeAttr('readonly');
    $('#password-checker-section').hide();

    const isMobile = mobileCheck();
    if (!isMobile) {
        // on mobile, this triggers the saved password popup
        // $('#reset-username').trigger('focus');
        $('#username').trigger('focus');
    }
};

const web2Login = (isHostedUi) => {
    // if (ConstantsCommon.AWS_COGNITO_HOSTED_UI) {
    if (isHostedUi) {
        hostedLogin();
    } else {
        setHtmlShowHide('#login-msg', '');
        const isWalletSectionHidden = $('#signin-social-wallet-section').css('display') === 'none';
        if (!isWalletSectionHidden) {
            $('#label-sign-in-using').hide();
            $('#sign-in-up-web2-custom').show();
            // $('#reset-verification-code-section').hide();
            // $('#change-password-footer-section').hide();
            cognitoNavSignIn();
            // $('#username').val('');
            // $('#password').val('');
            // envelope is not solid type
            // $('#signin-web2-custom-icon').removeClass('fa-regular');
            $('#signin-web2-custom-icon').removeClass('fa-envelope');
            // $('#signin-web2-custom-icon').addClass('fa-solid');
            $('#signin-web2-custom-icon').addClass('fa-arrow-left');
            $('#signin-web2-custom-desc').html('Back');
            $('#signin-social-wallet-section').hide();

            // already done in cognitoNavSignIn();
            // const isMobile = mobileCheck();
            // if (!isMobile) {
            //     // on mobile, this triggers the saved password popup
            //     $('#username').trigger('focus');
            // }
        } else {
            abortAuthFlowCalls();
            $('#confirm-web2-custom').hide();
            // $('#forgot-password-web2-custom').hide();
            // $('#change-password-web2-custom').hide();

            $('#label-sign-in-using').show();
            $('#sign-in-up-web2-custom').hide();
            // envelope is not solid type
            // $('#signin-web2-custom-icon').addClass('fa-regular');
            $('#signin-web2-custom-icon').addClass('fa-envelope');
            // $('#signin-web2-custom-icon').removeClass('fa-solid');
            $('#signin-web2-custom-icon').removeClass('fa-arrow-left');
            $('#signin-web2-custom-desc').html('Email');
            $('#signin-social-wallet-section').show();
        }
    }
};

const onCognitoChangePasswordSuccess = (elem) => {
    // $('#sign-in-up-web2-custom').show();
    // cognitoNavSignIn();
    // $('#change-password-web2-custom').hide();
    // $('#password').removeAttr('onkeyup');
    // $('#change-password-checker-section').html('');
    // $('#change-password-checker-section').hide();
    $(elem).attr('disabled', false);
    web2Login(false);
    // setHtmlShowHide('#login-msg', 'Password successfully changed! Please sign-in below.');
    showToast('Password successfully changed!', true);
};

const cognitoChangePassword = (e, elem) => {
    $(elem).attr('disabled', true);
    if (ConstantsCommon.AWS_COGNITO_CUSTOM_UI_DEBUG_FLOW) {
        onCognitoChangePasswordSuccess(elem);
        return;
    }
    const username = $('#username').val().trim();
    const verificationCode = $('#reset-verification-code').val();
    const newPassword = $('#password').val();
    const passwordConfirm = $('#change-password-confirm').val();
    if (!username) {
        showToast('Username is required');
        $(elem).attr('disabled', false);
        return;
    }
    if (!verificationCode) {
        showToast('Verification code is required');
        $(elem).attr('disabled', false);
        return;
    }
    if (!newPassword) {
        showToast('Password is required');
        $(elem).attr('disabled', false);
        return;
    }
    const {
        strength,
        strengthTotal,
    } = checkPasswordStrength(newPassword);
    if (strength !== strengthTotal) {
        showToast('Password does not meet the minimum level of security');
        $(elem).attr('disabled', false);
        return;
    }
    if (!passwordConfirm) {
        showToast('Confirm password is required');
        $(elem).attr('disabled', false);
        return;
    }
    if (newPassword !== passwordConfirm) {
        showToast('Password must match confirm password');
        $(elem).attr('disabled', false);
        return;
    }
    const poolData = {
        UserPoolId: ConstantsCommon.awsOptionsPkce.awsCognitoUserPoolId,
        ClientId: ConstantsCommon.awsOptionsPkce.awsCognitoClientId,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const userData = {
        Username: username,
        Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    const {
        rid,
        controller: _controller,
    } = getRidController();
    cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
            if (!authManager.checkRidX(rid, ridProviderFn(), '1a')) {
                return;
            }
            // console.log('Password confirmed!');
            onCognitoChangePasswordSuccess(elem);
        },
        onFailure(err) {
            console.log(`${window.logPrefix()}Failed to change password: [${err.stack || err.message}]`);
            if (!authManager.checkRidX(rid, ridProviderFn(), '1a')) {
                return;
            }
            // setHtmlShowHide('#login-msg', err.message);
            showToast(err.message);
            $(elem).attr('disabled', false);
        },
    });
};

const onCognitoResetPasswordSuccess = (elem, emailMask) => {
    setHtmlShowHide('#login-msg', `We have sent a code by email to ${emailMask}. Enter it below to reset your password.`);
    // showToast(`We have sent a code by email to ${emailMask}. Enter it below to reset your password.`, true);
    $('#button-cognito-change-password').attr('disabled', false);
    // $('#change-password-username').val($('#reset-username').val());
    $('#reset-verification-code').val('');
    $('#change-password-confirm').val('');
    // $('#forgot-password-web2-custom').hide();
    // $('#change-password-web2-custom').show();
    // $('#reset-verification-code-section').show();
    // $('#change-password-footer-section').show();
    // $('#password-wrapper').show();
    // $('#password-confirm-wrapper').show();
    // $('#password-wrapper').html('');
    // $('#change-password-wrapper').html(getPasswordFieldHtml('new-password'));
    // // $('#password').val('');

    $('.sign-up-flow').hide();
    $('.confirm-account-flow').hide();
    $('.reset-password-flow').hide();
    $('.sign-in-flow').hide();
    $('.change-password-flow').show();
    $('#username').attr('readonly', true);
    $('#password-checker-section').hide();
    const password = $('#password').val();
    if (password.length > 0) {
        checkPasswordStrength(password);
        $('#password-checker-section').show();
    }
    toggleShowHidePassword('#icon-show-password', '#password', true);

    $('#password').attr('onkeyup', 'bobbobAuth.onPasswordKeyup(event, this);');
    // $('#password-checker-section').html('');
    // $('#change-password-checker-section').html(getPasswordCheckerHtml());
    // $('#change-password-checker-section').hide();
    $(elem).attr('disabled', false);
    const isMobile = mobileCheck();
    if (!isMobile) {
        // on mobile, this triggers the saved password popup
        $('#reset-verification-code').trigger('focus');
    }
};

const cognitoResetPassword = (e, elem) => {
    $(elem).attr('disabled', true);
    if (ConstantsCommon.AWS_COGNITO_CUSTOM_UI_DEBUG_FLOW) {
        onCognitoResetPasswordSuccess(elem, 'test@test.com');
        return;
    }
    const username = $('#username').val().trim();
    if (!username) {
        showToast('Username is required');
        $(elem).attr('disabled', false);
        return;
    }
    const poolData = {
        UserPoolId: ConstantsCommon.awsOptionsPkce.awsCognitoUserPoolId,
        ClientId: ConstantsCommon.awsOptionsPkce.awsCognitoClientId,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const userData = {
        Username: username,
        Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    const {
        rid,
        controller: _controller,
    } = getRidController();
    cognitoUser.forgotPassword({
        onSuccess(data) {
            // successfully initiated reset password request
            console.log(`${window.logPrefix()}forgotPassword data [${JSON.stringify(data, null, 4)}]`);
            if (!authManager.checkRidX(rid, ridProviderFn(), '1a')) {
                return;
            }
            const emailMask = data.CodeDeliveryDetails.Destination;
            onCognitoResetPasswordSuccess(elem, emailMask);
        },
        onFailure(err) {
            console.log(`${window.logPrefix()}Failed to reset password using custom UI: [${err.stack || err.message}]`);
            if (!authManager.checkRidX(rid, ridProviderFn(), '1a')) {
                return;
            }
            // setHtmlShowHide('#login-msg', err.message);
            showToast(err.message);
            $(elem).attr('disabled', false);
        },
        // Optional automatic callback
        // inputVerificationCode(data) {
        //     console.log(`Code sent to: ${data}`);
        //     const code = document.getElementById('code').value;
        //     const newPassword = document.getElementById('new_password').value;
        //     cognitoUser.confirmPassword(verificationCode, newPassword, {
        //         onSuccess() {
        //             console.log('Password confirmed!');
        //         },
        //         onFailure(err) {
        //             console.log('Password not confirmed!');
        //         },
        //     });
        // },
    });
};

const onCognitoSignupSuccess = (elem, emailMask) => {
    setHtmlShowHide('#login-msg', `We have sent a code by email to ${emailMask}. Enter it below to confirm your account.`);
    // showToast(`We have sent a code by email to ${emailMask}. Enter it below to confirm your account.`, true);
    $('#button-cognito-confirm-account').attr('disabled', false);
    $('#button-cognito-send-new-code').attr('disabled', false);
    $('#verification-code').val('');
    // $('#sign-in-up-web2-custom').hide();
    // $('#verification-code').attr('data-bb-username', usernameCreated);
    // $('#confirm-web2-custom').show();
    $('.sign-up-flow').hide();
    $('.reset-password-flow').hide();
    $('.sign-in-flow').hide();
    $('.change-password-flow').hide();
    $('.confirm-account-flow').show();
    $('#username').removeAttr('readonly');
    $('#password-checker-section').hide();

    $(elem).attr('disabled', false);
    const isMobile = mobileCheck();
    if (!isMobile) {
        // on mobile, this triggers the saved password popup
        $('#verification-code').trigger('focus');
    }
};

const cognitoSignup = (e, elem) => {
    $(elem).attr('disabled', true);
    if (ConstantsCommon.AWS_COGNITO_CUSTOM_UI_DEBUG_FLOW) {
        onCognitoSignupSuccess(elem, 'test@test.com');
        return;
    }
    const username = $('#username').val().trim();
    // const email = $('#signUpFormEmail').val().toLowerCase();
    const email = $('#email').val();
    const password = $('#password').val();
    if (!username) {
        showToast('Username is required');
        $(elem).attr('disabled', false);
        return;
    }
    if (!email) {
        showToast('Email is required');
        $(elem).attr('disabled', false);
        return;
    }
    if (!isEmail(email)) {
        showToast('Email is not valid');
        $(elem).attr('disabled', false);
        return;
    }
    if (!password) {
        showToast('Password is required');
        $(elem).attr('disabled', false);
        return;
    }
    const {
        strength,
        strengthTotal,
    } = checkPasswordStrength(password);
    if (strength !== strengthTotal) {
        showToast('Password does not meet the minimum level of security');
        $(elem).attr('disabled', false);
        return;
    }
    const poolData = {
        UserPoolId: ConstantsCommon.awsOptionsPkce.awsCognitoUserPoolId,
        ClientId: ConstantsCommon.awsOptionsPkce.awsCognitoClientId,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const attributeList = [];
    const dataEmail = {
        Name: 'email',
        Value: email,
    };
    const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);
    attributeList.push(attributeEmail);
    const {
        rid,
        controller: _controller,
    } = getRidController();
    userPool.signUp(username, password, attributeList, null, (
        err,
        result,
    ) => {
        if (err) {
            console.log(`${window.logPrefix()}Failed to sign up using custom UI: [${err.stack || err.message}]`);
            if (!authManager.checkRidX(rid, ridProviderFn(), '1a')) {
                return;
            }
            // setHtmlShowHide('#login-msg', err.message);
            showToast(err.message);
            $(elem).attr('disabled', false);
            return;
        }
        console.log(`${window.logPrefix()}cognitoSignup result [${JSON.stringify(result, null, 4)}]`);
        if (!authManager.checkRidX(rid, ridProviderFn(), '1a')) {
            return;
        }
        // const cognitoUserResult = result.user;
        // console.log(`user name is ${cognitoUser.getUsername()}`);
        // const emailFirst = email.substring(0, 1);
        // const emailMask = `${emailFirst}***@${emailFirstAfterAt}***`;
        const _usernameCreated = result.user.username;
        const emailMask = result.codeDeliveryDetails.Destination;
        onCognitoSignupSuccess(elem, emailMask);
    });
};

const onCognitoResendCodeSuccess = (elem, emailMask) => {
    setHtmlShowHide('#login-msg', `We have sent a code by email to ${emailMask}. Enter it below to confirm your account.`);
    // showToast(`We have sent a code by email to ${emailMask}. Enter it below to confirm your account.`, true);
    $(elem).attr('disabled', false);
};

const cognitoResendCode = (e, elem) => {
    $(elem).attr('disabled', true);
    if (ConstantsCommon.AWS_COGNITO_CUSTOM_UI_DEBUG_FLOW) {
        onCognitoResendCodeSuccess(elem, 'test@test.com');
        return;
    }
    // const username = $('#verification-code').attr('data-bb-username');
    const username = $('#username').val().trim();
    if (!username) {
        showToast('Username is required');
        $(elem).attr('disabled', false);
        return;
    }
    const poolData = {
        UserPoolId: ConstantsCommon.awsOptionsPkce.awsCognitoUserPoolId,
        ClientId: ConstantsCommon.awsOptionsPkce.awsCognitoClientId,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const userData = {
        Username: username,
        Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    const {
        rid,
        controller: _controller,
    } = getRidController();
    cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
            console.log(`${window.logPrefix()}Failed to resend code using custom UI: [${err.stack || err.message}]`);
            if (!authManager.checkRidX(rid, ridProviderFn(), '1a')) {
                return;
            }
            // setHtmlShowHide('#login-msg', err.message);
            showToast(err.message);
            $(elem).attr('disabled', false);
            return;
        }
        console.log(`${window.logPrefix()}cognitoResendCode result [${JSON.stringify(result, null, 4)}]`);
        if (!authManager.checkRidX(rid, ridProviderFn(), '1a')) {
            return;
        }
        const emailMask = result.CodeDeliveryDetails.Destination;
        onCognitoResendCodeSuccess(elem, emailMask);
    });
};

const onCognitoConfirmAccountSuccess = (elem) => {
    // $('#confirm-web2-custom').hide();
    // $('#sign-in-up-web2-custom').show();
    // cognitoNavSignIn();
    $(elem).attr('disabled', false);
    web2Login(false);
    // setHtmlShowHide('#login-msg', 'Account successfully confirmed! Please sign-in below.');
    showToast('Account successfully confirmed!', true);
};

const cognitoConfirmAccount = (e, elem) => {
    $(elem).attr('disabled', false);
    if (ConstantsCommon.AWS_COGNITO_CUSTOM_UI_DEBUG_FLOW) {
        onCognitoConfirmAccountSuccess(elem);
        return;
    }
    const verificationCode = $('#verification-code').val();
    // const username = $('#verification-code').attr('data-bb-username');
    const username = $('#username').val().trim();
    if (!verificationCode) {
        showToast('Verification code is required');
        $(elem).attr('disabled', false);
        return;
    }
    if (!username) {
        showToast('Username is required');
        $(elem).attr('disabled', false);
        return;
    }
    const poolData = {
        UserPoolId: ConstantsCommon.awsOptionsPkce.awsCognitoUserPoolId,
        ClientId: ConstantsCommon.awsOptionsPkce.awsCognitoClientId,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const userData = {
        Username: username,
        Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    const {
        rid,
        controller: _controller,
    } = getRidController();
    cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
        if (err) {
            console.log(`${window.logPrefix()}Failed to confirm account using custom UI: [${err.stack || err.message}]`);
            if (!authManager.checkRidX(rid, ridProviderFn(), '1a')) {
                return;
            }
            // setHtmlShowHide('#login-msg', err.message);
            showToast(err.message);
            $(elem).attr('disabled', false);
            return;
        }
        console.log(`cognitoConfirmAccount result: ${result}`);
        if (!authManager.checkRidX(rid, ridProviderFn(), '1a')) {
            return;
        }
        onCognitoConfirmAccountSuccess(elem);
    });
};

const onCognitoSigninSuccess = (elem) => {
    $(elem).attr('disabled', false);
    // web2Login(false);
};

const cognitoSignin = (e, elem, isResignin = false) => {
    // if manually locked app, we check redirected flag, it will be set when session timed out
    // No, we need to sign in to validate password!
    // if (!authManager.hasRedirected) {
    //     console.log(`${window.logPrefix()}cognitoSignin authManager.hasRedirected [${authManager.hasRedirected}], not signing in`);
    //     return;
    // }
    $(elem).attr('disabled', true);
    if (ConstantsCommon.AWS_COGNITO_CUSTOM_UI_DEBUG_FLOW) {
        onCognitoSigninSuccess(elem);
        // hide to trigger password update
        // $('#sign-in-up-web2-custom').hide();
        // Don't need remote call to trigger password update
        // fetch('/ping').then((_response) => {
        // });
        web2Login(false);
        return;
    }
    const passwordEid = isResignin ? '#resignin-password' : '#password';
    const username = $('#username').val().trim();
    const password = $(passwordEid).val();
    if (!username) {
        showToast('Username is required');
        $(elem).attr('disabled', false);
        return;
    }
    if (!password) {
        showToast('Password is required');
        $(elem).attr('disabled', false);
        return;
    }
    // window.loggedInUsername = username;

    const authenticationData = {
        Username: username,
        Password: password,
    };
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
        authenticationData,
    );
    const poolData = {
        UserPoolId: ConstantsCommon.awsOptionsPkce.awsCognitoUserPoolId,
        ClientId: ConstantsCommon.awsOptionsPkce.awsCognitoClientId,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const userData = {
        Username: username,
        Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    const {
        rid,
        controller,
    } = getRidController();

    /** @type {AuthManager} */
    const authManager = window.authManager;
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
            if (!authManager.checkRidX(rid, ridProviderFn(), '1a')) {
                return;
            }
            console.log(`${window.logPrefix()}cognitoSignin result [${JSON.stringify(result, null, 4)}]`);
            // authManager.cognitoUser.getSession((err, session) => {
            //     if (err) {
            //         console.log(`${window.logPrefix()}Failed to get session [${err.message || JSON.stringify(err)}]`);
            //         return;
            //     }
            //     console.log(`${window.logPrefix()}session [${JSON.stringify(session, null, 4)}] [${session.isValid()}]`);
            // });
            const state = JSON.parse(localStorage.getItem('oauth2authcodepkce-state') || '{}');
            const exp = result.accessToken.payload.exp;
            const stateAccessToken = {
                value: result.accessToken.jwtToken,
                clockDrift: result.clockDrift,
                // payload: result.accessToken.payload,
                expiry: (new Date(exp * 1000)).toString(),
                timeUntilTokenExpire: exp,
            };
            state.accessToken = stateAccessToken;
            const idToken = {
                value: result.idToken.jwtToken,
                // payload: result.idToken.payload,
            };
            state.idToken = idToken;
            const refreshToken = {
                value: result.refreshToken.token,
                // payload: result.refreshToken.payload,
            };
            state.refreshToken = refreshToken;
            // state.hasWeb2 = '1';
            localStorage.setItem('oauth2authcodepkce-state', JSON.stringify(state));
            localStorage.setItem('sign-in-timestamp', luxon.DateTime.now().valueOf());
            // hide/show lockapp is done in authManager.signInApp()
            // setHtmlShowHide('#login-msg', 'Validating sign-in...');
            await authManager.signInApp(
                state.accessToken.value,
                state.idToken.value,
                state.refreshToken.value,
                undefined,
                true,
                controller.signal,
                rid,
                ridProviderFn,
                isResignin,
            );
            if (!authManager.checkRidX(rid, ridProviderFn(), '1a')) {
                return;
            }
            if (authManager.startupLoggedOut) {
                // clear storage, scenario:
                // user is not in whitelist, non invite msg is displayed
                // on refresh, it loads app and gets time out msg, but should really show login page
                await window.doLogoutAndCleanup(true);
                if (!authManager.checkRidX(rid, ridProviderFn(), '1a')) {
                    return;
                }
            }
            onCognitoSigninSuccess(elem);
        },
        onFailure(err) {
            console.log(`${window.logPrefix()}Failed to sign-in using custom UI: [${err.stack || err.message}]`);
            if (!authManager.checkRidX(rid, ridProviderFn(), '1a')) {
                return;
            }
            // setHtmlShowHide('#login-msg', err.message);
            showToast(err.message);
            $(elem).attr('disabled', false);
        },
    });
};

const checkDownloadGnosisLedger = async (type, skipDisplayModal = false) => {
    if (window.globalData.bobbobLoaded[type]) {
        return;
    }
    const start = luxon.DateTime.now().valueOf();
    if (!skipDisplayModal) {
        $('#loading-lib-title').html(`${type} Loader`);
        $('#loading-lib-progress').css('width', '0%');
        $('#loading-lib-text').html(`Loading...`);
        $(`#button-loading-lib-retry`).prop('disabled', true);
        $(`#button-loading-lib-ok`).prop('disabled', true);
        $(`#modal-loading-lib`).modal('show');
    }
    try {
        window.globalData.loadLibProgress = 0;
        window.globalData.libsToLoad = 0;

        const libs1 = [
            getLibrary(`js/bobbob-${type.toLowerCase()}-${window.BOBBOB_VERSION}${window.BOBBOB_MINIFIED_EXT}.js`, (_url) => {
                if (!skipDisplayModal) {
                    window.globalData.loadLibProgress += 1;
                    const percentage = `${Math.floor(window.globalData.loadLibProgress / window.globalData.libsToLoad * 100)}%`;
                    $('#loading-lib-progress').css('width', percentage);
                    $('#loading-lib-text').html(`Progress [${window.globalData.loadLibProgress}/${window.globalData.libsToLoad}]`);
                }
            }),
        ];
        window.globalData.libsToLoad += libs1.length;
        await Promise.all(libs1);
        if (!skipDisplayModal) {
            $('#loading-lib-progress').removeClass('bg-warning');
            $('#loading-lib-progress').addClass('bg-success');
            $(`#button-loading-lib-ok`).prop('disabled', false);
            const elapsed = start - bobbobAuth.luxon.DateTime.now().valueOf();
            const waitTime = 1250;
            if (elapsed < waitTime) {
                const diff = waitTime - elapsed;
                await bobbobAuth.sleep(diff);
            }
            $(`#modal-loading-lib`).modal('hide');
        }
        window.globalData.bobbobLoaded[type] = true;
    } catch (error) {
        console.log(`${window.logPrefix()}Failed to load lib: [${error.stack || error.message}]`);
        if (!skipDisplayModal) {
            $(`#button-loading-lib-retry`).prop('disabled', false);
        }
    }
};

const checkDownloadGnosisConnect = async (e, elem, skipDisplayModal = false) => {
    await checkDownloadGnosisLedger('Gnosis', skipDisplayModal);
    bobbobGnosis.connectGnosis(e, elem, true, false);
};

const checkDownloadLedgerConnect = async (e, elem, signIn, hideSuccess, skipDisplayModal = false) => {
    if (ConstantsCommon.LEDGER_LIB_RUNTIME) {
        await checkDownloadGnosisLedger('Ledger', skipDisplayModal);
    }
    bobbobLedger.connectLedger(e, elem, signIn, hideSuccess);
};

const checkDownloadLedgerLink = async (e, elem, skipDisplayModal = false, displayModal = false) => {
    if (ConstantsCommon.LEDGER_LIB_RUNTIME) {
        await checkDownloadGnosisLedger('Ledger', skipDisplayModal);
    }
    bobbobLedger.linkLedger(displayModal);
};

const onKeyPressSearch = (e, type) => {
    if (e.keyCode === 13) {
        e.preventDefault();
        // doRefreshDropTxns();
        const isDisabled = $(`#${type}-button-search`).prop('disabled');
        if (!isDisabled) {
            $(`#${type}-button-search`).trigger('click');
        }
    }
    return false;
};

const onEnterKeyPress = (e, elem, btnElem) => {
    if (e.keyCode === 13) {
        e.preventDefault();
        const isDisabled = $(btnElem).prop('disabled');
        if (!isDisabled) {
            $(btnElem).trigger('click');
        }
    }
    return false;
};

const onEnterKeyPressCustomFlow = (e, elem) => {
    let btnElem;
    const isSignUpFooterSectionHidden = $('#sign-up-footer-section').css('display') === 'none';
    const isSignInFooterSectionHidden = $('#sign-in-footer-section').css('display') === 'none';
    const isChangePasswordFooterSectionHidden = $('#change-password-footer-section').css('display') === 'none';
    const isResetPasswordSectionHidden = $('#cognito-reset-password-section').css('display') === 'none';
    const isVerificationCodeSectionHidden = $('#verification-code-wrapper').css('display') === 'none';

    if (!isSignUpFooterSectionHidden) {
        btnElem = '#button-cognito-sign-up';
    } else if (!isVerificationCodeSectionHidden) {
        btnElem = '#button-cognito-confirm-account';
    } else if (!isSignInFooterSectionHidden) {
        btnElem = '#button-cognito-sign-in';
    } else if (!isResetPasswordSectionHidden) {
        btnElem = '#button-cognito-reset-password';
    } else if (!isChangePasswordFooterSectionHidden) {
        btnElem = '#button-cognito-change-password';
    } else {
        throw new Error('INternal error: Cannot find button to proceed!');
    }
    onEnterKeyPress(e, elem, btnElem);
};

const bobbobAuth = {
    ConstantsCommon,
    Logger,
    connectMetamask,
    luxon,
    // Web3,
    switchNetwork,
    AuthManager,
    BrowserAuthRenderer,
    UtilsCommonMin,
    GlobalData,
    sleep,
    updateTopBannerOnDisconnected,
    updateTopBannerOnChainChange,
    updateWalletToolOnChainChange,
    checkMetamaskOnboarding,
    initialiseMetamask,
    linkMetaMask,
    preAppModals,
    // connectLedger,
    sigUtil,
    Web3Helper,
    AmazonCognitoIdentity,
    cognitoSignin,
    cognitoSignup,
    web2Login,
    hostedLogin,
    toggleShowHidePassword,
    onPasswordKeyup,
    cognitoResendCode,
    cognitoConfirmAccount,
    cognitoNavSignIn,
    cognitoNavSignUp,
    cognitoNavForgottenPassword,
    cognitoResetPassword,
    cognitoChangePassword,
    setHtmlShowHide,
    checkDownloadLedgerLink,
    checkDownloadLedgerConnect,
    checkDownloadGnosisConnect,
    checkDownloadGnosisLedger,
    onEnterKeyPressCustomFlow,
    onEnterKeyPress,
    onKeyPressSearch,
    getRidController,
    ridProviderFn,
};
if (debugLoader) { console.log(`indexR [${indexR++}]`); }
if (typeof window !== 'undefined') {
    const target = window.bobbobAuth || {};
    window.bobbobAuth = Object.assign(target, bobbobAuth);
}
module.exports = bobbobAuth;
